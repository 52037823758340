interface StringValidator {
  isAcceptable (s: string): boolean
}

const pictureReg = /\.(jpg|jpeg|png|GIF|JPG|PNG)$/
class PictureValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return pictureReg.test(s)
  }
}

const charactersReg = /^[\u4e00-\u9fa5]+$/
class CharactersValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return charactersReg.test(s)
  }
}

const creditCodeReg = /^[A-Z0-9]+$/
class CreditCodeValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return creditCodeReg.test(s)
  }
}

const idCardReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
class IDCardValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return idCardReg.test(s)
  }
}

const lowercaseNumberReg = /^[0-9a-z]{3,19}$/
class LowercaseNumberValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return lowercaseNumberReg.test(s)
  }
}

const websiteReg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'*+,;=.]+$/
class WebsiteValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return websiteReg.test(s)
  }
}

// 邮箱校验
const emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
class EmailValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return emailReg.test(s)
  }
}

// 手机号校验
const phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/
class PhoneValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return phoneReg.test(s)
  }
}

// 包含大小写字母、数字、特殊字符
const upperLowerNumSpecoalReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/
class UpperLowerNumSpecoalValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return upperLowerNumSpecoalReg.test(s)
  }
}

// 包含大写、小写、数字
const upperLowerNumReg = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).+$/
class UpperLowerNumValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return upperLowerNumReg.test(s)
  }
}

// 包含大写、小写、特殊字符
const upperLowerSpecoalReg = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-z])[^0-9]+$/
class UpperLowerSpecoalValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return upperLowerSpecoalReg.test(s)
  }
}

// 包含小写、特殊字符、数字
const lowerSpecoalNumReg = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?!.*[A-Z]).+$/
class LowerSpecoalNumValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return lowerSpecoalNumReg.test(s)
  }
}

// 包含小写、大写字母
const upperLower = /^(?=.*[a-z])(?=.*[A-Z])[^0-9\W]+$/
class UpperLowerValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return upperLower.test(s)
  }
}

// 包含小写、特殊字符
const lowerSpecoalReg = /^[^A-Z0-9]*[a-z]+[^A-Z0-9]*$/
class LowerSpecoalValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return lowerSpecoalReg.test(s)
  }
}

// 包含小写字母、数字
const lowerNumReg = /^(?=.*[a-z])(?=.*\d)[a-z\d]+$/
class LowerNumValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return lowerNumReg.test(s)
  }
}

// 包含小写字母
const lowerReg = /^[a-z]+$/
class LowerValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return lowerReg.test(s)
  }
}

// 包含大写字母、字符
const upperSpecoalReg = /^[^a-z0-9]*[A-Z][^a-z0-9]*$/
class UpperSpecoalValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return upperSpecoalReg.test(s)
  }
}

// 包含大写字母、数字
const upperNumReg = /^(?=.*[A-Z])(?=.*\d)[^a-z\W]+$/
class UpperNumValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return upperNumReg.test(s)
  }
}

// 包含大写、特殊字符、数字
const upperSpecoalNumReg = /^(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9])[^a-z]+$/
class UpperSpecoalNumReg implements StringValidator {
  isAcceptable (s: string): boolean {
    return upperSpecoalNumReg.test(s)
  }
}

// 包含大写字母
const upperReg = /^[A-Z]+$/
class UpperValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return upperReg.test(s)
  }
}

// 包含数字、特殊字符
const specosalNumReg = /^[0-9!@#$%^&*()]+$/
class SpecosalNumValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return specosalNumReg.test(s)
  }
}

// 包含数字
const numReg = /^\d+$/
class NumValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return numReg.test(s)
  }
}

// 包含特殊字符
const specoalReg = /[^A-Za-z0-9]/
class SpecoalRegValidator implements StringValidator {
  isAcceptable (s: string): boolean {
    return specoalReg.test(s)
  }
}

export {
  PictureValidator,
  CharactersValidator,
  CreditCodeValidator,
  IDCardValidator,
  LowercaseNumberValidator,
  WebsiteValidator,
  UpperLowerNumValidator,
  UpperLowerSpecoalValidator,
  LowerSpecoalNumValidator,
  UpperLowerValidator,
  LowerSpecoalValidator,
  LowerNumValidator,
  LowerValidator,
  UpperSpecoalValidator,
  UpperNumValidator,
  UpperValidator,
  UpperSpecoalNumReg,
  SpecosalNumValidator,
  NumValidator,
  SpecoalRegValidator,
  UpperLowerNumSpecoalValidator,
  EmailValidator,
  PhoneValidator
}
