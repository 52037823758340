import {
  LowerNumValidator,
  LowerSpecoalNumValidator,
  LowerSpecoalValidator,
  LowerValidator,
  NumValidator,
  SpecoalRegValidator,
  SpecosalNumValidator,
  UpperLowerNumSpecoalValidator,
  UpperLowerNumValidator,
  UpperLowerSpecoalValidator,
  UpperLowerValidator,
  UpperNumValidator,
  UpperSpecoalNumReg,
  UpperSpecoalValidator,
  UpperValidator
} from '@/interface/validator'
import { checkPasswordApi } from '@/api/login'
import { RegisterInterface } from '@/interface/registerInterface'
import { reactive } from 'vue'
import { Rule } from 'ant-design-vue/es/form'

const usePasswordVerifiers = () => {
  const passwordRule: RegisterInterface.PasswordRuleFace = reactive({
    limit: 0,
    lowerCase: true,
    num: true,
    specoal: true,
    upperCase: true
  })

  const queryPasswordRule = () => {
    checkPasswordApi().then(res => {
      Object.assign(passwordRule, res.data)
    })
  }

  const checkPassword = async (_rule: Rule, value: string) => {
    if (value === '') {
      return Promise.reject(new Error('请输入密码'))
    } else {
      if (passwordRule.lowerCase && passwordRule.specoal && passwordRule.upperCase && passwordRule.num) {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/
        const upperLowerNumSpecoalValidator = new UpperLowerNumSpecoalValidator()
        if (!upperLowerNumSpecoalValidator.isAcceptable(value) && value.length < passwordRule.limit) {
          return Promise.reject(new Error(`密码长度至少是${passwordRule.limit}位，仅包含大小写字母、数字、特殊字符`))
        } else if (value.length < passwordRule.limit) {
          return Promise.reject(new Error(`密码长度至少是${passwordRule.limit}位`))
        } else if (!regex.test(value)) {
          return Promise.reject(new Error('密码仅包含大小写字母、数字、特殊字符'))
        } else {
          return Promise.resolve()
        }
      } else if (passwordRule.lowerCase) {
        if (passwordRule.upperCase && passwordRule.num) { // 小写字母、大写字母、数字
          const upperLowerNumValidator = new UpperLowerNumValidator()
          if (!upperLowerNumValidator.isAcceptable(value) && value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位，仅包含大小写字母、数字`))
          } else if (value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位`))
          } else if (!upperLowerNumValidator.isAcceptable(value)) {
            return Promise.reject(new Error('仅包含大小写字母、数字'))
          } else {
            return Promise.resolve()
          }
        } else if (passwordRule.upperCase && passwordRule.specoal) { // 小写字母、大写字母、特殊字符
          const upperLowerSpecoalValidator = new UpperLowerSpecoalValidator()
          if (!upperLowerSpecoalValidator.isAcceptable(value) && value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位，仅包含大小写字母、特殊字符`))
          } else if (value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位`))
          } else if (!upperLowerSpecoalValidator.isAcceptable(value)) {
            return Promise.reject(new Error('密码仅包含大小写字母、特殊字符'))
          } else {
            return Promise.resolve()
          }
        } else if (passwordRule.num && passwordRule.specoal) { // 小写字母、数字、特殊字符
          const lowerSpecoalNumValidator = new LowerSpecoalNumValidator()
          if (!lowerSpecoalNumValidator.isAcceptable(value) && value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位，仅包含小写字母、数字、特殊字符`))
          } else if (value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位`))
          } else if (!lowerSpecoalNumValidator.isAcceptable(value)) {
            return Promise.reject(new Error('密码仅包含小写字母、数字、特殊字符'))
          } else {
            return Promise.resolve()
          }
        } else if (passwordRule.upperCase) { // 小写、大写
          const upperLowerValidator = new UpperLowerValidator()
          if (!upperLowerValidator.isAcceptable(value) && value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位，仅包含大小写字母`))
          } else if (value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位`))
          } else if (!upperLowerValidator.isAcceptable(value)) {
            return Promise.reject(new Error('密码仅包含大小写字母'))
          } else {
            return Promise.resolve()
          }
        } else if (passwordRule.specoal) { // 小写、特殊字符
          const lowerSpecoalValidator = new LowerSpecoalValidator()
          if (!lowerSpecoalValidator.isAcceptable(value) && value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位，仅包含小写字母、特殊字符`))
          } else if (value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位`))
          } else if (!lowerSpecoalValidator.isAcceptable(value)) {
            return Promise.reject(new Error('密码仅包含小写字母、特殊字符'))
          } else {
            return Promise.resolve()
          }
        } else if (passwordRule.num) { // 小写、数字
          const lowerNumValidator = new LowerNumValidator()
          if (!lowerNumValidator.isAcceptable(value) && value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位，仅包含小写字母、数字`))
          } else if (value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位`))
          } else if (!lowerNumValidator.isAcceptable(value)) {
            return Promise.reject(new Error('密码仅包含小写字母、数字'))
          } else {
            return Promise.resolve()
          }
        } else { // 仅小写字母
          const lowerValidator = new LowerValidator()
          if (!lowerValidator.isAcceptable(value) && value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位，仅包含小写字母`))
          } else if (value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位`))
          } else if (!lowerValidator.isAcceptable(value)) {
            return Promise.reject(new Error('密码仅包含小写字母'))
          } else {
            return Promise.resolve()
          }
        }
      } else if (passwordRule.upperCase) {
        if (passwordRule.num && passwordRule.specoal) { // 大写字母、数字、特殊字符
          const upperSpecoalNumReg = new UpperSpecoalNumReg()
          if (!upperSpecoalNumReg.isAcceptable(value) && value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位，仅包含大写字母、数字、特殊字符`))
          } else if (value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位`))
          } else if (!upperSpecoalNumReg.isAcceptable(value)) {
            return Promise.reject(new Error('仅包含大写字母、数字、特殊字符'))
          } else {
            return Promise.resolve()
          }
        } else if (passwordRule.specoal) { // 大写、特殊字符
          const upperSpecoalValidator = new UpperSpecoalValidator()
          if (!upperSpecoalValidator.isAcceptable(value) && value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位，仅包含大写字母、特殊字符`))
          } else if (value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位`))
          } else if (!upperSpecoalValidator.isAcceptable(value)) {
            return Promise.reject(new Error('密码仅包含大写字母、特殊字符'))
          } else {
            return Promise.resolve()
          }
        } else if (passwordRule.num) { // 大写、数字
          const upperNumValidator = new UpperNumValidator()
          if (!upperNumValidator.isAcceptable(value) && value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位，仅包含大写字母、数字`))
          } else if (value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位`))
          } else if (!upperNumValidator.isAcceptable(value)) {
            return Promise.reject(new Error('密码仅包含大写字母、数字'))
          } else {
            return Promise.resolve()
          }
        } else { // 仅大写字母
          const upperValidator = new UpperValidator()
          if (!upperValidator.isAcceptable(value) && value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位，仅包含大写字母`))
          } else if (value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位`))
          } else if (!upperValidator.isAcceptable(value)) {
            return Promise.reject(new Error('密码仅包含大写字母'))
          } else {
            return Promise.resolve()
          }
        }
      } else if (passwordRule.num) {
        if (passwordRule.specoal) { // 数字、特殊字符
          const specosalNumValidator = new SpecosalNumValidator()
          if (!specosalNumValidator.isAcceptable(value) && value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位，仅包含数字、特殊字符`))
          } else if (value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位`))
          } else if (!specosalNumValidator.isAcceptable(value)) {
            return Promise.reject(new Error('密码仅包含数字、特殊字符'))
          } else {
            return Promise.resolve()
          }
        } else { // 仅数字
          const numValidator = new NumValidator()
          if (!numValidator.isAcceptable(value) && value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位，仅包含数字`))
          } else if (value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位`))
          } else if (!numValidator.isAcceptable(value)) {
            return Promise.reject(new Error('密码仅包含数字'))
          } else {
            return Promise.resolve()
          }
        }
      } else if (passwordRule.specoal) {
        if (passwordRule.specoal) { // 仅特殊字符
          const specoalRegValidator = new SpecoalRegValidator()
          if (!specoalRegValidator.isAcceptable(value) && value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位，仅包含特殊字符`))
          } else if (value.length < passwordRule.limit) {
            return Promise.reject(new Error(`密码至少${passwordRule.limit}位`))
          } else if (!specoalRegValidator.isAcceptable(value)) {
            return Promise.reject(new Error('密码仅特殊字符'))
          } else {
            return Promise.resolve()
          }
        }
      }
    }
  }

  return {
    queryPasswordRule,
    checkPassword
  }
}

export {
  usePasswordVerifiers
}
