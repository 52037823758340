import http from '@/utils/http'
import { RegisterInterface } from '@/interface/registerInterface'

// 发送手机验证码
const sendPhoneCodeByparamsApi = (data: RegisterInterface.MsmCodeFace, fn: any): Promise<Response> => {
  return http({
    method: 'post',
    url: '/user/userLogin/sendPhoneCode',
    data: data,
    cancelToken: fn()
  })
}

// 发送邮箱验证码
const sendEmailCodeApi = (data: RegisterInterface.MsmCodeFace): Promise<Response> => {
  return http({
    method: 'post',
    url: '/user/userLogin/sendEmailCode',
    data: data
  })
}

// 手机号注册
const saveUserByPhoneApi = (data: RegisterInterface.ReqParamsByPhoneFace): Promise<Response> => {
  return http({
    method: 'post',
    url: '/user/userLogin/savePhoneUser',
    data: data
  })
}

// 修改用户密码
const updateUserPasswordApi = (data: RegisterInterface.UpdatePasswordFace) => {
  return http({
    method: 'post',
    url: '/user/user/updateUserPassword',
    data: data
  })
}

// 邮箱注册
const saveUserByEmailApi = (data : RegisterInterface.ReqParamsByEmailFace): Promise<Response> => {
  return http({
    method: 'post',
    url: '/user/userLogin/saveEmailUser',
    data: data
  })
}

// 验证邮箱是否被注册
const userEmailIsexistApi = (email: string, fn: any) => {
  return http({
    method: 'get',
    url: `/user/userLogin/userEmailIsexist/${email}/`,
    cancelToken: fn()
  })
}

// 查询是否能注册
const queryCanRegisterApi = () => {
  return http({
    method: 'get',
    url: '/user/userLogin/userRegisterFlag'
  })
}

export {
  sendPhoneCodeByparamsApi,
  saveUserByPhoneApi,
  sendEmailCodeApi,
  saveUserByEmailApi,
  userEmailIsexistApi,
  updateUserPasswordApi,
  queryCanRegisterApi
}
