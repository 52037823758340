import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "code-box" }
const _hoisted_2 = { class: "code" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "code-box" }
const _hoisted_5 = { class: "code" }
const _hoisted_6 = {
  href: "https://docs.aiserver.cn/SitonCloud/service_agreement/",
  target: "_blank"
}
const _hoisted_7 = {
  href: "https://docs.aiserver.cn/SitonCloud/privacy_policy/",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_st_button = _resolveComponent("st-button")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    class: "register-form",
    model: _ctx.registerByEmail,
    onFinish: _ctx.handleRegister
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form_item, {
        name: "email",
        rules: [
        {
          required: true,
          validator: _ctx.checkEmail
        }
      ]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            size: "large",
            autocomplete: "off",
            placeholder: _ctx.$t('login.emailPlaceholder'),
            value: _ctx.registerByEmail.email,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.registerByEmail.email) = $event))
          }, {
            prefix: _withCtx(() => [
              _createVNode(_component_svg_icon, {
                "icon-class": "ic_phone",
                class: "ic-svg"
              })
            ]),
            _: 1
          }, 8, ["placeholder", "value"])
        ]),
        _: 1
      }, 8, ["rules"]),
      _createVNode(_component_a_form_item, {
        name: "password",
        rules: [
        {
          required: true,
          validator: _ctx.checkPassword
        }
      ]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input_password, {
            size: "large",
            autocomplete: "off",
            placeholder: _ctx.$t('login.password'),
            value: _ctx.registerByEmail.password,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.registerByEmail.password) = $event))
          }, {
            prefix: _withCtx(() => [
              _createVNode(_component_svg_icon, {
                "icon-class": "ic_password",
                class: "ic-svg"
              })
            ]),
            _: 1
          }, 8, ["placeholder", "value"])
        ]),
        _: 1
      }, 8, ["rules"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_form_item, {
          class: "item-code",
          name: "code"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              size: "large",
              autocomplete: "off",
              placeholder: _ctx.$t('login.codeNum'),
              value: _ctx.registerByEmail.code,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.registerByEmail.code) = $event))
            }, {
              prefix: _withCtx(() => [
                _createVNode(_component_svg_icon, {
                  "icon-class": "ic_prove",
                  class: "ic-svg"
                })
              ]),
              _: 1
            }, 8, ["placeholder", "value"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.codeNum)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: 'data:image/png;base64,' + _ctx.codeNum,
                onClick: _cache[3] || (_cache[3] = () => _ctx.$emit('query'))
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_a_form_item, {
          class: "item-code",
          name: "emailCode"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              size: "large",
              autocomplete: "off",
              placeholder: _ctx.$t('login.emailCode'),
              value: _ctx.registerByEmail.emailCode,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.registerByEmail.emailCode) = $event))
            }, {
              prefix: _withCtx(() => [
                _createVNode(_component_svg_icon, {
                  "icon-class": "ic_prove",
                  class: "ic-svg"
                })
              ]),
              _: 1
            }, 8, ["placeholder", "value"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.second === 60)
            ? (_openBlock(), _createBlock(_component_st_button, {
                key: 0,
                type: "primary",
                class: "code-btn",
                onClick: _cache[5] || (_cache[5] = () => _ctx.handleSendCode(_ctx.codeId)),
                disabled: _ctx.disabled
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("login.sendCode")), 1)
                ]),
                _: 1
              }, 8, ["disabled"]))
            : (_openBlock(), _createBlock(_component_st_button, {
                key: 1,
                type: "primary",
                disabled: _ctx.second !== 60,
                class: "code-btn"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("login.isSent")) + "（" + _toDisplayString(_ctx.second) + "s） ", 1)
                ]),
                _: 1
              }, 8, ["disabled"]))
        ])
      ]),
      _createVNode(_component_a_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_checkbox, {
            checked: _ctx.checked,
            "onUpdate:checked": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.checked) = $event))
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("login.agreeProtocolOne")), 1),
              _createElementVNode("a", _hoisted_6, " 《" + _toDisplayString(_ctx.$t("login.plant")) + _toDisplayString(_ctx.$t("login.service")) + "》 ", 1),
              _createElementVNode("a", _hoisted_7, " 《" + _toDisplayString(_ctx.$t("login.plant")) + _toDisplayString(_ctx.$t("login.privacy")) + "》 ", 1)
            ]),
            _: 1
          }, 8, ["checked"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_st_button, {
            type: "primary",
            size: "large",
            class: "login-btn",
            "html-type": "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" 注册 ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "onFinish"]))
}