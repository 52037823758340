import { computed, reactive, ref } from 'vue'
import { RegisterInterface } from '@/interface/registerInterface'
import { queryImgCodeApi } from '@/api/login'
import './register.scss'

const useRegister = ():any => {
  const currentIndex = ref<boolean>(true)
  const currentComponent = computed(() => {
    if (currentIndex.value) {
      return 'RegisterByPhone'
    } else {
      return 'RegisterByEmail'
    }
  })
  const handleChangeRegisterType = () => {
    currentIndex.value = !currentIndex.value
    queryCodeInfo()
  }
  const imgCodeInfo: RegisterInterface.imgCodeInfoFace = reactive({
    codeId: '',
    codeNum: ''
  })

  const queryCodeInfo = (): void => {
    queryImgCodeApi().then(res => {
      imgCodeInfo.codeId = res.data.codeId
      imgCodeInfo.codeNum = res.data.codeNum
    })
  }

  return {
    currentIndex,
    currentComponent,
    handleChangeRegisterType,
    imgCodeInfo,
    queryCodeInfo
  }
}
export {
  useRegister
}
