
import { defineComponent, onMounted } from 'vue'
import LoginRegisterBg from '@/components/Login-register-bg/LoginRegisterBg.vue'
import RegisterByPhone from './components/RegisterByPhone.vue'
import RegisterByEmail from './components/RegisterByEmail.vue'
import { useRegister } from './registerModule'

export default defineComponent({
  components: {
    LoginRegisterBg,
    RegisterByPhone,
    RegisterByEmail
  },
  setup () {
    const {
      currentComponent,
      currentIndex,
      handleChangeRegisterType,
      queryCodeInfo,
      imgCodeInfo,
      registerByPhone,
      registerByEmail
    } = useRegister()

    onMounted(() => {
      queryCodeInfo()
    })

    return {
      currentComponent,
      currentIndex,
      handleChangeRegisterType,
      imgCodeInfo,
      queryCodeInfo,
      registerByPhone,
      registerByEmail
    }
  }
})
