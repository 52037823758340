import { computed, onMounted, reactive, ref } from 'vue'
import type { FormInstance } from 'ant-design-vue'
import { message } from 'ant-design-vue'
import type { Rule } from 'ant-design-vue/es/form'
import { RegisterInterface } from '@/interface/registerInterface'
import { sendPhoneCodeByparamsApi, saveUserByPhoneApi } from '@/api/register'
import { encrtpt } from '@/utils/jsencrypt'
import { useRouter } from 'vue-router'
import { usePasswordVerifiers } from '@/utils/passwordValidator'
import { PhoneValidator } from '@/interface/validator'
import { useCancelAjax } from '@/utils/cancel-ajax'

export const useRegisterByPhone = (emit: any):any => {
  const router = useRouter()

  const {
    queryPasswordRule,
    checkPassword
  } = usePasswordVerifiers()

  const {
    cancelFnList,
    createCancelList
  } = useCancelAjax()

  const registerByPhone: RegisterInterface.ReqParamsByPhoneFace = reactive({
    phone: '',
    password: '',
    code: '',
    smsCode: ''
  })

  const second = ref<number>(60)

  const countDown = ():void => {
    const timer = setInterval(() => {
      if (second.value === 0) {
        second.value = 60
        clearInterval(timer)
      } else {
        second.value = second.value - 1
      }
    }, 1000)
  }

  const handleSendCode = (codeId: string):void => {
    if (cancelFnList.length !== 0) {
      cancelFnList.value.forEach((cancelFn: any, index: number) => {
        cancelFn()
        delete cancelFnList.value[index]
      })
    }
    const params = {
      codeId: codeId,
      codeNum: registerByPhone.code as string,
      phone: registerByPhone.phone
    }
    sendPhoneCodeByparamsApi(params, createCancelList).then(() => {
      countDown()
    }).catch(err => {
      if (!(err.code === 'ERR_CANCELED')) {
        emit('query')
      }
    })
  }

  const form = ref<FormInstance>()

  const checked = ref<boolean>(false)

  onMounted(() => {
    queryPasswordRule()
  })

  const validatorPhone = async (_rule:Rule, value: string): Promise<any> => {
    if (!value) {
      return Promise.reject(new Error('请输入手机号'))
    } else {
      const phoneValidator = new PhoneValidator()
      if (phoneValidator.isAcceptable(value)) {
        return Promise.resolve()
      } else {
        return Promise.reject(new Error('手机号格式错误'))
      }
    }
  }

  const handleRegister = (value: RegisterInterface.ReqParamsByPhoneFace):void => {
    if (!checked.value) {
      message.warning(
        '请先阅读并同意《' +
          '思腾合力AI开放平台' +
          '服务条款》《' +
          '思腾合力AI开放平台' +
          '隐私政策》'
      )
    } else {
      const params = {
        phone: value.phone,
        password: encrtpt(value.password) as string,
        smsCode: value.smsCode
      }
      saveUserByPhoneApi(params).then(() => {
        message.success('注册成功', 0.5).then(() => {
          router.push({
            path: '/login'
          })
        })
      })
    }
  }

  const disabled = computed(() => {
    return !(registerByPhone.phone && registerByPhone.code)
  })

  return {
    registerByPhone,
    handleSendCode,
    second,
    form,
    validatorPhone,
    handleRegister,
    disabled,
    checkPassword,
    checked
  }
}
