import { computed, onMounted, reactive, ref } from 'vue'
import { RegisterInterface } from '@/interface/registerInterface'
import { sendEmailCodeApi, saveUserByEmailApi, userEmailIsexistApi } from '@/api/register'
import { encrtpt } from '@/utils/jsencrypt'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import {
  EmailValidator
} from '@/interface/validator'
import { Rule } from 'ant-design-vue/es/form'
import { usePasswordVerifiers } from '@/utils/passwordValidator'
import { useCancelAjax } from '@/utils/cancel-ajax'

export const useRegisterByEmail = (emit: any):any => {
  const router = useRouter()

  const {
    cancelFnList,
    createCancelList
  } = useCancelAjax()

  const {
    checkPassword,
    queryPasswordRule
  } = usePasswordVerifiers()

  const registerByEmail: RegisterInterface.ReqParamsByEmailFace = reactive({
    email: '',
    password: '',
    code: '',
    emailCode: ''
  })

  const second = ref<number>(60)

  const countDown = ():void => {
    const timer = setInterval(() => {
      if (second.value === 0) {
        second.value = 60
        clearInterval(timer)
      } else {
        second.value = second.value - 1
      }
    }, 1000)
  }

  const handleSendCode = (codeId: string):void => {
    if (cancelFnList.length !== 0) {
      cancelFnList.value.forEach((cancelFn: any, index: number) => {
        cancelFn()
        delete cancelFnList.value[index]
      })
    }

    userEmailIsexistApi(registerByEmail.email, createCancelList).then(res => {
      if (!res.data) {
        const params = {
          codeNum: registerByEmail.code as string,
          codeId: codeId,
          email: registerByEmail.email as string
        }
        sendEmailCodeApi(params).then(() => {
          countDown()
        }).catch(err => {
          if (!(err.code === 'ERR_CANCELED')) {
            emit('query')
          }
        })
      }
    })
  }

  const disabled = computed(() => {
    return !(registerByEmail.email && registerByEmail.code)
  })

  const checked = ref<boolean>(false)

  const handleRegister = (value: RegisterInterface.ReqParamsByEmailFace):void => {
    if (!checked.value) {
      message.warning(
        '请先阅读并同意《' +
          '思腾合力AI开放平台' +
          '服务条款》《' +
          '思腾合力AI开放平台' +
          '隐私政策》'
      )
    } else {
      const params = {
        email: value.email,
        password: encrtpt(value.password) as string,
        emailCode: value.emailCode
      }
      saveUserByEmailApi(params).then(() => {
        message.success('注册成功', 0.5).then(() => {
          router.push('/login')
        })
      })
    }
  }

  const checkEmail = async (_rule: Rule, value: string) => {
    const emailValidator = new EmailValidator()
    if (value === '') {
      return Promise.reject(new Error('请输入邮箱地址'))
    } else {
      if (!emailValidator.isAcceptable(value)) {
        return Promise.reject(new Error('邮箱格式错误'))
      } else {
        return Promise.resolve()
      }
    }
  }

  onMounted(() => {
    queryPasswordRule()
  })

  return {
    registerByEmail,
    second,
    countDown,
    handleSendCode,
    disabled,
    handleRegister,
    checkPassword,
    checkEmail,
    checked
  }
}
