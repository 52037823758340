
import { defineComponent } from 'vue'
import { useRegisterByPhone } from '../js/registerByPhone'
export default defineComponent({
  emits: ['query'],
  props: {
    codeNum: {
      type: String,
      required: true
    },
    codeId: {
      type: String,
      required: true
    }
  },
  setup (props: any, { emit }) {
    const {
      registerByPhone,
      handleSendCode,
      second,
      form,
      validatorPhone,
      handleRegister,
      disabled,
      checkPassword,
      checked
    } = useRegisterByPhone(emit)
    return {
      registerByPhone,
      handleSendCode,
      second,
      form,
      validatorPhone,
      handleRegister,
      disabled,
      checkPassword,
      checked
    }
  }
})
