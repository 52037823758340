
import { defineComponent } from 'vue'
import { useRegisterByEmail } from '../js/registerByemail'
export default defineComponent({
  emits: ['query'],
  props: {
    codeNum: {
      type: String,
      required: true
    },
    codeId: {
      type: String,
      required: true
    }
  },
  setup (_, { emit }) {
    const {
      second,
      countDown,
      registerByEmail,
      handleSendCode,
      disabled,
      handleRegister,
      checkPassword,
      checkEmail,
      checked
    } = useRegisterByEmail(emit)

    return {
      second,
      countDown,
      registerByEmail,
      handleSendCode,
      disabled,
      handleRegister,
      checkPassword,
      checkEmail,
      checked
    }
  }
})
